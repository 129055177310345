import axios from "axios";
import Config from '../config.json';

class Api {

    config = {};
    headers = {};
    accessToken = null;

    constructor(config) {
        this.config = config;
        this.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
        };
        this.setToken(null, true);
    }

    setToken = async (token = null, df = false) => {
        if (df)
            this.accessToken = await localStorage.getItem('nestheadToken');
        else
            this.accessToken = token;
    }

    prepareUrl = (path) => {
        path = path.trim();

        if (path.indexOf('://') !== -1)
            return path;

        return this.config.apiUrl_demo.trim() + path; // for demo
        // return this.config.apiUrl.trim() + path; // for live
    } 

    prepareParams = (method, path, conf = {}) => {

        if (conf.token) {
            this.accessToken = conf.token;
            delete conf.token;
        }

        if (conf?.accessToken || this.accessToken)
            this.headers['Authorization'] = 'Bearer ' + (conf?.accessToken || this.accessToken).toString();

        var params = {
            url: this.prepareUrl(path),
            method: method,
            headers: this.headers,
        };

        if (conf) {
            Object.keys(conf).forEach(key => {
                params[key] = conf[key];
            });
        }
        return params;
    }

    get = async (path, conf = {}) => {
        return await this.call('get', path, null, conf);
    }

    post = async (path, data, conf = {}) => {
        return await this.call('post', path, data, conf);
    }

    put = async (path, data, conf = {}) => {
        return await this.call('put', path, data, conf);
    }

    delete = async (path, conf = {}) => {
        return await this.call('delete', path, null, conf);
    }

    upload = async (path, name, uri, conf = {}) => {
        var ret = {}, formData, params = { method: 'POST' };

        try {
            // console.log("API CALL: " + path + " - Start ==========================");

            if (typeof uri == 'object') {
                formData = new FormData();

                Object.entries(uri).map(([idx, url]) => {
                    if (typeof url == 'object') {
                        if (url?.path) {
                            var fname = url?.fieldName || name;

                            if (('#' + url.path).includes('data:image')) {
                                formData.append(`${fname}[${idx}]`, url.path);
                            } else {
                                var ext = '', file = url.path.trim();
                                ext = file.slice((file.lastIndexOf(".") - 1 >>> 0) + 2);
                                if (ext == '') ext = 'jpg';

                                var mtype = 'image';
                                if (['MP4', 'MPEG', 'M4A', 'FMP4', 'WEBM'].includes(ext.toUpperCase()))
                                    mtype = 'video';
                                if (['WAV'].includes(ext.toUpperCase()))
                                    mtype = 'audio';

                                formData.append(`${fname}[${idx}]`, {
                                    uri: file,
                                    name: url?.name || file.match(/^.*?([^\\/.]*)[^\\/]*$/)[1] + '.' + ext,
                                    type: url?.type || mtype + '/' + (ext == 'jpg' ? 'jpeg' : ext).toLowerCase(),
                                });
                            }
                        }
                    } else {
                        if (('#' + url).includes('data:image')) {
                            formData.append(`${name}[${idx}]`, url);
                        } else {
                            var ext = '', file = url.trim()
                            ext = file.slice((file.lastIndexOf(".") - 1 >>> 0) + 2);
                            if (ext == '') ext = 'jpg';
                            var mtype = 'image';
                            if (['MP4', 'MPEG', 'M4A', 'FMP4', 'WEBM'].includes(ext.toUpperCase()))
                                mtype = 'video';
                            if (['WAV'].includes(ext.toUpperCase()))
                                mtype = 'audio';

                            formData.append(`${name}[${idx}]`, {
                                uri: file,
                                name: file.match(/^.*?([^\\/.]*)[^\\/]*$/)[1] + '.' + ext,
                                type: mtype + '/' + (ext == 'jpg' ? 'jpeg' : ext).toLowerCase(),
                            });
                        }
                    }
                });

                if (conf?.data && typeof conf?.data == 'object') {
                    Object.entries(conf?.data).map(([_nm, _val]) => {
                        if (typeof _val == 'object') {
                            Object.entries(_val).map(([_nm2, _val2]) => {
                                formData.append(`${_nm}[${_nm2}]`, _val2);
                            });
                        } else
                            formData.append(_nm, _val);
                    });
                }

                //console.log(formData);

            } else {

                if (('#' + uri).includes('data:image')) {
                    formData = JSON.stringify({ base64: uri });
                    params['dataType'] = 'jsonp';
                } else {

                    var ext = '', file = uri.trim()
                    formData = new FormData();
                    ext = file.slice((file.lastIndexOf(".") - 1 >>> 0) + 2);
                    if (ext == '') ext = 'jpg';
                    var mtype = 'image';
                    if (['MP4', 'MPEG', 'M4A', 'FMP4', 'WEBM'].includes(ext.toUpperCase()))
                        mtype = 'video';
                    if (['WAV'].includes(ext.toUpperCase()))
                        mtype = 'audio';

                    formData.append(name, {
                        uri: file,
                        name: file.match(/^.*?([^\\/.]*)[^\\/]*$/)[1] + '.' + ext,
                        type: mtype + '/' + (ext.toLowerCase() == 'jpg' ? 'jpeg' : ext).toLowerCase(),
                    });

                    params['headers'] = {
                        'Accept': 'application/json',
                        "Content-Type": "multipart/form-data"
                    };

                    if (this.accessToken)
                        params['headers']['Authorization'] = 'Bearer ' + this.accessToken.toString();
                }
            }

            params['body'] = formData;

            await fetch(this.prepareUrl(path), params).then((response) => response.json()).then((resp) => {
                ret = resp;
            }).catch((error) => {
                ret = { success: false, message: error.message || "Unable to process request", data: {} };
            });

        } catch (err) {
            ret = { success: false, message: "Unable to process request", data: {} };
        }
        // console.log(ret);
        // console.log("API CALL: - END ==========================");

        return ret;
    }

    call = async (method, path, data = {}, conf = {}) => {

        var _frmt = (conf && conf?.external) || false;
        if (conf && conf?.external) delete conf.external;

        var ret = {}, params = this.prepareParams(method, path, conf);

        try {

            if (data)
                params['data'] = data;

            // console.log("API CALL: " + method + " - Start ==========================");
            // console.log(params);

            await axios(params).then((resp) => {
                if (_frmt)
                    ret = { success: true, message: null, data: resp };
                else {
                    ret = resp.data;
                    if (!ret.success && !ret.message)
                        ret['message'] = "Unable to process request";
                }

            }).catch((error) => {
                ret = { success: false, message: error.message || "Unable to process request", data: {} };
            });
            // console.log(ret);
            // console.log("API CALL: - END ==========================");
        } catch (err) {
            ret = { success: false, message: "Unable to process request", data: {} };
        }

        return ret;
    }
}

export default new Api(Config);