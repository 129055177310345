import React, { useEffect, useState } from 'react';
import ModelListing from '../components/ModelListing';
import { useNavigate } from 'react-router-dom';
import CharacterDetails from './CharacterDetails';
import { useLocation } from 'react-router-dom';
import Api from '../services/Api';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import { setQuestsdata, resetQuests, removeQuestsdata } from '../actions/questActions';
import { useDispatch } from 'react-redux';
import Buttons from '../components/Buttons';
import { toast } from "react-toastify";
import Slider from 'react-slick';

// let _characters = [
//   {
//     img: require('../assets/categories/beliefs.png'),
//     name: 'Beliefs'
//   },
//   {
//     img: require('../assets/categories/biases.png'),
//     name: 'Biases'
//   },
//   {
//     img: require('../assets/categories/emotions.png'),
//     name: 'Emotions'
//   },
//   {
//     img: require('../assets/categories/gratitude.png'),
//     name: 'Gratitude'
//   },
//   {
//     img: require('../assets/categories/habits.png'),
//     name: 'Habits'
//   },
//   {
//     img: require('../assets/categories/neurotransmitters.png'),
//     name: 'Neurotransmitters'
//   },
//   {
//     img: require('../assets/categories/triggers.png'),
//     name: 'Triggers'
//   },
// ]

const Models = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [_helmet, setHelmet] = useState({ title: "models", des: 'models description' })
  const [loaded, setLoaded] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState('new');
  const [characterCategory, setCharacterCategory] = useState(false);
  const [categories, setCategories] = useState([])
  const [settings, setSettings] = useState([])
  const dispatch = useDispatch()

  const isLogged = useSelector((state) => state.user.isLogged);
  const selected = useSelector((state) => state.quests.selected);
  const default_character = useSelector((state) => state.characotors.default_charactor);

  const navigate = useNavigate();
  const location = useLocation();

  let width = window.innerWidth;

  useEffect(() => {
    document.documentElement.style.setProperty('--dynamic-dots-color', characterCategory?.default_character ? characterCategory?.default_character?.color : default_character?.color || "#000");
  }, [default_character, characterCategory])

  useEffect(() => {

    const _slidesToShow = (width < 400) ? 2 : (width > 400 && width < 768) ? 3 : (width > 768 && width < 900) ? 4 : (width > 900 && width < 1250) ? 5 : 6;

    let _settings = {
      infinite: false,
      dots: true,
      speed: 500,
      slidesToShow: _slidesToShow,
      slidesToScroll: 2,
      autoplay: false,
      draggable: false,
      arrows: false
    };
    setSettings(_settings)
  }, [window.innerWidth])


  const details = (e, ele) => {
    ele.preventDefault()
    navigate(`/models/${(e?.seo || 'model')}`)

    // if (isLogged) {
    //   navigate(`/models/${(e?.seo || 'model')}`)
    // } else {
    //   navigate((e?.seo || 'model'))
    // }
  }

  const onSort = (id, sort) => {
    let _data = [...data]
    let index = _data.findIndex(item => item.id == id)

    if ((index == 0 && sort == "up") || (index == (_data.length - 1) && sort == 'down')) {
      toast.error(`No item is ${sort == "down" ? "below" : "above"}!`)
      return
    }


    Api.get("characters/set-order/" + id + "/" + sort)
      .then((res) => {
        if (res.success) {
          if (sort == "up") {
            let before = _data[index]
            _data[index] = _data[index - 1]
            _data[index - 1] = before
          } else {
            let before = _data[index]

            _data[index] = _data[index + 1]
            _data[index + 1] = before
          }
          setData(_data)
        } else {
          toast.error(res?.message || "Some Error occured!")
        }
      })
      .catch((err) => {
        toast.error(err?.message || "Some Error occured!")
      })
  }

  const updateLike = (e) => {

    if (selected.find((item) => item.id == e.id)) {
      selected.filter((item) => item.id != e.id)
      dispatch(removeQuestsdata(e))
    } else {
      dispatch(setQuestsdata(e))
    }

    // Api.post('characters/like',
    //   {
    //     type: e.liked ? 0 : 1,
    //     character_id: JSON.stringify(e.id)
    //   })
    //   .then((res) => {
    //     if (res.success) {
    //       let _data = []
    //       data.map((ele) => {
    //         if (ele.id == e.id) {
    //           ele['liked'] = e.liked ? false : true
    //           _data.push(ele)
    //         } else {
    //           _data.push(ele)
    //         }
    //       })
    //       setData(_data)
    //     } else {

    //     }
    //   })
    //   .catch(() => {

    //   })
  }

  useEffect(() => {
    localStorage.setItem('charactersdata', JSON.stringify(selected))
  }, [selected])

  useEffect(() => {
    setLoading(true)
    setHelmet({ title: "models", des: 'models description' });
    let url = isLogged ? 'characters/list' : 'characters/public/list'
    let characters = Api.post(url);
    let _categories = Api.get('chat-category/list ');

    Promise.all([characters, _categories])
      // Promise.all([characters, categories])
      .then((res) => {
        if (res[0].success) {
          setData(res[0].data)
        } else {
          setData([])
        }

        if (res[1].success) {
          setCategories(res[1].data);
        } else {
          setCategories([]);

        }
      })
      .catch((err) => {
        setData([])
      })
      .finally(() => {
        setLoading(false)
      })

    // return () => dispatch(resetQuests())
  }, [location])

  useEffect(() => {
    setLoaded(false)
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }, [_helmet])

  const onFilterChange = (e) => {
    setSelectedFilter(e.target.value)
    setLoading(true)

    let url = characterCategory ? `chat-category/detail/${characterCategory.id}${selectedFilter && ("?filter=" + e.target.value)}` : `characters${isLogged ? "" : '/public'}/list?filter=${e.target.value}`
    Api.post(url)
      .then((res) => {
        if (res.success) {
          if (characterCategory) {
            setData(res.data[0].character)
          } else {
            setData(res.data)
          }
        } else {
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }


  // useEffect(() => {
  //   setLoading(true);
  //   // let _characterItems = localStorage.getItem("charactersItems")
  //   // if (_characterItems != null) {
  //   //     setCharacterItems(JSON.parse(_characterItems))
  //   // }

  //   Api.post('chat-category/list')
  //     .then((res) => {
  //       if (res.success) {
  //         setCategories(res.data)
  //         // scrollToTop()
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  // }, [])

  const onCategorySelect = (item) => {
    console.log(item)
    if (characterCategory.id == item.id) {
      setCharacterCategory(false);
      let url = isLogged ? 'characters/list' : 'characters/public/list'
      Api.post(url)
        .then((res) => {
          if (res.success) {
            setData(res?.data || [])
          } else {
            setData([])
          }
        })
      // let categories = Api.get('categories');

    } else {
      setCharacterCategory(item)
      Api.get(`chat-category/detail/${item.id}${selectedFilter && ("?filter=" + selectedFilter)} `)
        .then((res) => {
          if (res.success) {
            setData(res?.data[0].character || [])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <div className='main_outer_container'>
      <div className='main_model_dailog_container' style={{ backgroundColor: characterCategory?.default_character ? characterCategory.default_character.color : default_character.color }}>
        <div className='model-page-dailoge'  >
          <div className='default-dialog-image-container' style={{ backgroundColor: characterCategory?.default_character ? characterCategory.default_character.color : default_character.color }} >
            <img src={characterCategory?.default_character ? characterCategory.default_character.image : default_character.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </div>
          <div style={{ margin: '0px 20px' }}>
            <div className='default-dailog-character-name' style={{ fontFamily: 'Dimbo' }}>{characterCategory?.default_character ? characterCategory.default_character.name : default_character.name}</div>
            <div className='default-dailog-character-des' style={{ fontFamily: 'Dimbo' }}>{characterCategory?.default_character ? characterCategory.default_character.description : default_character.description}</div>
          </div>
        </div>
      </div>
      <div className='page_container' style={{ margin: '0px auto', }} >
        {/*<div style={{ display: 'flex', alignItems: 'center' }} >
          <div className='main_model_image_text' >This is your NEST. The most fun way to understand and re-program your HEAD.</div>
          <img className='main_model_image' src={require('../assets/map_toxic.png')} />
        </div>*/}
        <div className='' >
          {loading ?
            <div className='image_container model-list-main-loading-container' >
              <div className='models_loading_container' ></div>
              <div className='models_loading_container' ></div>
              {width >= 400 && <div className='models_loading_container' ></div>}
              {width >= 768 && <div className='models_loading_container' ></div>}
              {width >= 900 && <div className='models_loading_container' ></div>}
            </div>
            :
            <div className='model-list-main-container' >
              <Slider {...settings}>
                {categories.map((item, i) => (
                  <div className='model-list-outer-container' >
                    <div key={i} onClick={() => onCategorySelect(item)} className={`character_category_container_slider ${characterCategory.id == item.id && 'active'}`}>
                      <div className='image_container' >
                        <img src={item.image} width={"100%"} height={'100%'} style={{ objectFit: 'contain' }} />
                      </div>
                      <div className='text-center'  >{item.name}</div>
                    </div>
                  </div>
                ))
                }
              </Slider>
            </div>
          }
        </div>

        <div className='character_listing_container' >
          <div className='charactor_filter_container' >
            {/* <Buttons title={'Create Character'} loading={false} onClick={() => navigate('/create-character')} /> */}
            <div className='my-4 mx-2 text-right'>
              <select className='character_filter' value={selectedFilter} style={{ fontFamily: 'Dimbo' }} title='Filter' onChange={onFilterChange}>
                <option className='filter_options' value={'new'}>newest</option>
                <option className='filter_options' value={'name'} >name</option>
                <option className='filter_options' value={'popularity'}>popularity</option>
                {/* <option className='filter_options' style={{ display: 'none' }} selected={selectedFilter == 'default'} >default</option> */}
              </select>
            </div>
          </div>
          <ModelListing data={data} onClick={details} updateLike={updateLike} onSort={onSort} loading={loading} />
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default Models;